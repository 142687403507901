<template>
  <div class="p-2 card">
    <b-modal
      v-model="showModalQrcode"
      centered
      hide-footer
    >
      <b-row class="mb-1 ml-1 mr-1">
        <b-alert
          v-height-fade.appear
          :show="dismissCountDown"
          dismissible
          class="mb-0  w-100"
          variant="success"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <div class="alert-body">
            <span class="ml-25">Link copiado para área de transferência</span>
          </div>
        </b-alert>
      </b-row>
      <b-row
        class="justify-content-center mb-2"
      >
        <span
          class="title-modal-ciclo"
        >
          QR Code Cora
        </span>
      </b-row>
      <b-row
        class="justify-content-center"
      >
        <qrcode-vue
          :value="Qrcode.value"
          :size="Qrcode.size"
          level="H"
        />
      </b-row>
      <b-row
        class="justify-content-center ml-2 mr-2 mt-1 mb-2"
      >
        <span
          class="subtitle-modal-ciclo"
        >
          Use a câmera do seu celular e leia este QR Code, ou se preferir, acesse o link abaixo:
        </span>
      </b-row>
      <b-row
        class="justify-content-center mb-1"
      >
        <b-button
          type="submit"
          variant="custom"
          class="mr-1"
          @click.prevent="copiarLink()"
        >
          <span>Copiar link</span>
        </b-button>
        <b-button
          type="submit"
          variant="custom"
          @click.prevent="abrirLink()"
        >
          <span>Acessar link</span>
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
      v-model="modalCiclos.showModal"
      id="modal-ciclo"
      ref="modal-ciclo"
      size="xl"
      centered
      hide-footer
      @close="fechaModalCiclo"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <CicloForm
        :Ciclo="modalCiclos.Ciclo"
        @closeModal="fechaModalCiclo"
      />
    </b-modal>
    <b-row class="mb-3">
      <b-col md="9" sm="12">
        <h1>Ciclos</h1>
        <p>
          Para realizar uma busca, selecione o(s) filtros necessário(s) e clique
          no botão buscar:
        </p>
      </b-col>

      <b-col md="3" sm="12">
        <b-link
          class="btn botao float-lg-right btn-custom"
          :to="{ name: 'ciclos-cadastrar' }"
          ><BIconPlusCircle /> &nbsp; Criar Novo Ciclo</b-link
        >
      </b-col>
    </b-row>

    <section class="filtros mt-2">
      <b-form>
        <b-row>
          <b-col xl="3" md="6">
            <b-form-group label="Nome Fantasia" label-for="nomeFantasia">
              <b-form-input
                id="nomeFantasia"
                v-model="busca.nomeFantasia"
                autocomplete="off"
                placeholder="Nome Fantasia"
              />
            </b-form-group>
          </b-col>

          <b-col xl="3" md="6">
            <b-form-group label="Produto" label-for="produto">
              <v-select
                id="produto"
                v-model="busca.modulo"
                :options="modulosSelect"
                variant="custom"
                item-text="descricao"
                item-value="id_modulo"
                label="descricao"
                placeholder="Produto"
              />
            </b-form-group>
          </b-col>

          <b-col xl="3" md="6">
            <b-form-group label="Status" label-for="situacao">
              <v-select
                id="situacao"
                v-model="busca.situacao"
                :options="situacaoSelect"
                variant="custom"
                placeholder="Status"
              />
            </b-form-group>
          </b-col>

          <b-col xl="3" md="6">
            <b-form-group
              label="Data de Início do Ciclo"
              label-for="dataDeInicioDoCiclo"
            >
              <flat-pickr
                id="dataDeInicioDoCiclo"
                v-model="busca.dataDeInicioDoCiclo"
                class="form-control"
                :config="busca.flatPickr"
                placeholder="Data de Início do Ciclo"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="3" md="6">
            <b-form-checkbox v-model="mostrarInativas" class="variant">
              Mostrar Empresas inativas
            </b-form-checkbox>
          </b-col>
        </b-row>

        <div class="w-100 d-flex justify-content-center my-2">
          <b-button
            class="mr-2"
            type="reset"
            variant="outline-secondary"
            @click="limparFiltros"
          >
            Limpar
          </b-button>

          <b-button
            @click.prevent="buscarDadosCiclos"
            ref="filtrosCiclos"
            type="submit"
            variant="custom"
          >
            Buscar
          </b-button>
        </div>
      </b-form>
    </section>

    <div
      v-if="carregandoTabela"
      class="
        w-100
        mt-5
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
      <span>carregando...</span>
    </div>

    <b-alert variant="primary" :show="tabela.semDados" class="mt-5">
      <div class="alert-body d-flex justify-content-center">
        <span class="text-primary">
          <strong class="text-primary">Nenhum registro encontrado.</strong>
          Realize novamente uma busca utilizando diferentes critérios.
        </span>
      </div>
    </b-alert>

    <section class="tabela-empresas" v-if="mostrarTabela">
      <b-row class="mb-2">
        <b-col sm="12">
          <div
            class="d-flex flex-row align-items-center justify-content-between"
          >
            <b-col sm="4" class="pl-0">
              <label class="mr-50">Visualizar</label>
              <b-form-select
                v-model="dadosPaginacao.quantidadeLinhasPadrao"
                class="w-50"
                size="sm"
                id="amounlinesVisualizer"
                label="option"
                @input="atualizaQuantidadeVisualizar($event)"
                :options="tabela.quantidadeLinhasVisualizar"
              />
            </b-col>
          </div>
        </b-col>

        <b-col sm="12" class="my-2">
          <b-table
            id="listagemUsuariosSesi"
            responsive
            @context-changed="ordenarTabela"
            sticky-header="100%"
            :busy.sync="tabela.tabelaOcupada"
            :no-local-sorting="false"
            :fields="tabela.fields"
            :items="tabela.items"
          >
            <template #cell(ciclo)="row">
              <div>
                Ciclo {{ row.item.id_ciclo_por_empresa }}
              </div>
              <div>
                Fase {{ row.item.id_fase }}
              </div>
            </template>

            <template #cell(situacao)="row">
              <b-badge
                :variant="getClasseSituacao(row.item)"
              >
                {{ row.item.situacao }}
              </b-badge>
            </template>

            <template #cell(periodo)="row">
              {{ geraStringPeriodo(row.item) }}
            </template>

            <template #cell(acoes)="row">
              <b-col class="mx-auto p-0">
                <div class="d-flex">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon"
                    @click="mostrarQrcode()"
                  >
                    <feather-icon icon="Link2Icon" />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="btn-icon ml-1"
                    @click="mostraModalCiclo(row.item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-if="verificaMostrarDash(row.item.situacao)"
                    variant="outline-primary"
                    class="btn-icon ml-1"
                    @click="
                      redirecionarParaPainelFiltrado(
                        row.item.id_empresa,
                        row.item.modulo_descricao,
                        row.item.descricao_questionario
                      )
                    "
                    ><feather-icon icon="BarChart2Icon"
                  /></b-button>
                </div>
              </b-col>
            </template>
          </b-table>
        </b-col>
        <b-col sm="12">
          <CustomPagination
            :paginacao="dadosPaginacao"
            @page-cliked="atualizarPaginaAtual"
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BIconPlusCircle,
  BLink,
  BRow,
  BSpinner,
  BTable,
  BBadge,
  BAlert,
  BFormCheckbox,
  BModal
} from "bootstrap-vue";
import vSelect from "vue-select";
import { heightFade } from '@core/directives/animations'
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import "animate.css";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import CicloForm from "@/views/pages/ciclos/EditarCiclos.vue";
import QrcodeVue from 'qrcode.vue';
export default {
  components: {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BLink,
    BRow,
    BIconPlusCircle,
    vSelect,
    flatPickr,
    BSpinner,
    BFormSelect,
    CustomPagination,
    BTable,
    BBadge,
    BAlert,
    CicloForm,
    BFormCheckbox,
    BModal,
    QrcodeVue
  },

  directives: {
    'height-fade': heightFade,
  },

  mounted() {
    this.dadosSelect();
  },

  data() {
    return {
      mostrarInativas: false,
      busca: {
        nomeFantasia: "",
        modulo: "",
        situacao: "",
        dataDeInicioDoCiclo: "",
        flatPickr: {
          locale: Portuguese,
          altInput: true,
          altFormat: "d/m/Y",
          dateFormat: "Y-m-d",
        },
      },
      modalCiclos: {
        showModal: false,
        Ciclo: {},
        updateCiclo: false,
      },
      dismissSecs: 3,
      dismissCountDown: 0,
      showModalQrcode: false,
      Qrcode: {
        value: process.env.VUE_APP_APP_URL+'/cora',
        size: 200,
      },
      atualizarRegistros: false,
      selecionarTodos: false,
      idUsuarioLogado: this.$store.state.sessions.userData.usuario.id_usuario,
      modulosSelect: [],
      situacaoSelect: [],
      mostrarTabela: false,
      carregandoTabela: false,
      dadosPaginacao: {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      },
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: "asc",
        campoOrdenado: "nome_fantasia",
        fields: [
          { key: "nome_fantasia", label: "Nome Fantasia", sortable: true },
          { key: "ciclo", label: "Ciclo | Fase" },
          { key: "modulo_descricao", label: "Produto", sortable: true },
          { key: "situacao", label: "Status", sortable: true },
          { key: "periodo", label: "Período" },
          { key: "acoes", label: "Ações" },
        ],
        items: [],
      },
    };
  },

  methods: {
    mostrarQrcode(){
      this.showModalQrcode = true;
    },
    abrirLink(){
      window.open(this.Qrcode.value, '_blank');
    },
    copiarLink(){
      navigator.clipboard.writeText(this.Qrcode.value);
      this.dismissCountDown = this.dismissSecs;
    },
    async dadosSelect() {
      this.situacaoSelect = [
        "Agendado",
        "Cancelado",
        "Concluído",
        "Em Andamento",
        "Rascunho",
      ];
      await this.retornaModulos();
    },
    verificaMostrarDash(situacao) {
      return !["Cancelado", "Agendado", "Rascunho"].includes(situacao);
    },

    buscarDadosCiclos(firstTime = null) {
      if (firstTime && !this.mostrarTabela) {
        this.carregandoTabela = true;
      }

      this.tabela.erroTabela = false;

      this.tabela.semDados = false;
      this.tabela.tabelaOcupada = true;
      this.$http
        .get(this.$api.CiclosBusca, {
          params: this.lidarComParametrosBuscaCiclos(),
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.mostrarTabela = true;
              this.tabela.items = response.data.data;

              this.tabela.tabelaOcupada = false;
              this.carregandoTabela = false;
              this.IniciarPaginacao(response.data);
              return;
            }

            this.carregandoTabela = false;
            this.tabela.semDados = true;
            this.mostrarTabela = false;
          }
        })
        .catch(() => {
          this.carregandoTabela = false;
          this.tabela.erroTabela = true;
        });
    },

    sucessoInserirAtualizar() {
      this.esconderModal();

      this.buscarDadosCiclos();
    },

    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy;
      this.tabela.ordem = contextoTabela.sortDesc ? "desc" : "asc";

      this.buscarDadosCiclos();
    },

    lidarComParametrosBuscaCiclos() {
      return {
        nome_fantasia: this.busca.nomeFantasia,
        moduloId: this.busca.modulo ? this.busca.modulo.id_modulo : "",
        situacao: this.busca.situacao,
        inicio: this.busca.dataDeInicioDoCiclo,
        columnName: this.tabela.campoOrdenado,
        columnOrder: this.tabela.ordem,
        perPage: this.dadosPaginacao.quantidadeLinhasPadrao,
        page: this.dadosPaginacao.paginaAtual,
        empresa_ativa: !this.mostrarInativas,
      };
    },

    limparFiltros() {
      this.busca = {
        nomeFantasia: "",
        modulo: "",
        situacao: "",
        dataDeInicioDoCiclo: "",
      };

      this.mostrarTabela = false;

      this.limparPaginacao();
    },

    limparPaginacao() {
      this.dadosPaginacao = {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      };
    },

    IniciarPaginacao(dadosPaginacao) {
      this.dadosPaginacao.daLinha = dadosPaginacao.from;
      this.dadosPaginacao.ateALinha = dadosPaginacao.to;
      this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
      this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
      this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
    },

    atualizarPaginaAtual(page) {
      this.dadosPaginacao.paginaAtual = page;
      this.buscarDadosCiclos();
    },

    atualizaQuantidadeVisualizar(event) {
      if (!event) {
        this.dadosPaginacao.quantidadeLinhasPadrao = 10;
      }

      this.dadosPaginacao.paginaAtual = 1;
      this.buscarDadosCiclos();
    },

    esconderModal() {
      this.limparCampos();
      this.mostrarModal = !this.mostrarModal;
    },

    retornaModulos() {
      this.$http.get(this.$api.ModulosProdutos).then((res) => {
        this.modulosSelect = res.data;
      });
    },
    geraStringPeriodo(item) {
      return (
        this.$helpers.converteDataBancoParaHumano(item.inicio, true) +
        " - " +
        this.$helpers.converteDataBancoParaHumano(item.termino, true)
      );
    },

    getClasseSituacao(item) {
      let classes = {
          agendado: "warning",
          cancelado: "danger",
          concluido: "primary",
          "em-andamento": "success",
          rascunho: "secondary",
        },
        situacao = this.$helpers.removeAcentos(
          item.situacao.toLowerCase().replaceAll(" ", "-")
        );
      return classes[situacao];
    },

    mostraModalCiclo(Ciclo) {
      this.modalCiclos.Ciclo = Ciclo;
      this.modalCiclos.updateCiclo = true;
      this.modalCiclos.showModal = true;
    },
    fechaModalCiclo() {
      this.modalCiclos.showModal = false;
      this.modalCiclos.updateCiclo = false;
      this.atualizarPaginaAtual(this.dadosPaginacao.paginaAtual);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    redirecionarParaPainelFiltrado(idEmpresa, modulo, nomeQuestionario) {
      if (modulo == "Planeja Saúde") {
        this.$router.push({
          name: "pesquisa-painel-planeja-empresa",
          params: {
            idEmpresa: idEmpresa
          },
        });

      } else {
        this.$router.push({
          name: "pesquisa-painel-empresa",
          params: {
            idEmpresa: idEmpresa,
            preSelecaoQuestionario: nomeQuestionario
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.title-modal-ciclo{
  width: 353px;
  height: 26px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  color: #5E5873;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.subtitle-modal-ciclo{
  width: 434px;
  height: 42px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6E6B7B;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.modal-header{
  background: #FFF !important;
}
</style>
